<template>
  <div class="nav-inner">
    <div class="nav-tab">
      <div class="tab">
        <div class="hotel-logo">
          <span class="logo-img" />
          <span>NovoCrédito</span>
        </div>
        <ul class="hotel-li">
          <li
            v-for="(item,index) in navData.list"
            :key="index"
            :class="navData.index === index ? 'select-item under-line': 'select-item'"
          >
            <a :href="item.link">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navData: {
      type: Object,
      default: function() {
        return null
      }
    }
  },
  methods: {
    goAnchor(selector) {
      this.$router.push({ name: selector })
    }
  }
}
</script>

<style lang="scss" scope>
.nav-inner {
  width: calc(100vw);
  background: #fff;
  padding:0px calc((100% - 1100px) / 2) 0;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 99999;
  .nav-tab {
    height: 70px;
    align-items: center;
    margin: 0 auto !important;
    // padding: 10px 0;
    .tab{
      margin: 0 auto;
    }
    .hotel-logo {
      float: left;
      height: 70px;
      line-height: 70px;
      span:nth-child(1) {
        display: inline-block;
        width: 100px;
        height: 50px;
        width: 180px;
        height: 75px;
        vertical-align:middle;
        background: url("~@/assets/images/loanImg/logo_2.png") no-repeat center;
        background-size: contain;
      }
      span:nth-child(2){
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        vertical-align:middle;
        margin-left: 10px;
      }
      // .logo-img{
      //   background: url("~@/assets/images/loanImg/logo_2.png") no-repeat center;
      // }
    }
    .hotel-li {
      float: right;
      height: 100%;
      li {
        display: inline-block;
        position: relative;
        // width: 16.66%;
        padding: 0 20px;
        height: 100%;
        font-size: 16px;
        text-align: center;
        line-height: 70px;
        white-space:nowrap;
        cursor: pointer;
        a{
          color: #999999;
          text-decoration: none;
        }
        &.under-line{
          color: $color-286BD9;
          a{
            color: #1BAE98;
          }
          // &::after{
          //   position: absolute;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   bottom: 0px;
          //   content: '';
          //   width: 90px;
          //   height: 3px;
          //   background: $color-286BD9;
          // }
        }
      }
    }
  }
}
@media only screen and (max-width: 665px) {
  .nav-tab {
    height:70px !important;
    width: calc(100vw);
    .hotel-logo{
      .logo-img{
        width: 160px !important;
        height: 60px !important;
      }
    }
    .hotel-li{
      background: white;
      width: calc(100vw);
      display: flex;
      li{
        line-height: 50px !important;
        padding: 0 10px !important;
      }
      .select-item {
        flex: 1;
      }
      a{
        font-size: 12px !important;
      }
    }
  }
}
</style>
